<template>
   <div>
        <LoadingIcon :isLoading="isLoading" />
        <div v-show="!isLoading && isAdmin">
            <BannerTemplate :bannerSource="roadtrip.mainProductPhotoURL">
                <div class="px-2 mt-n3 tripday-statistics-content position-relative">
                    <v-container class="pt-0">
                        <v-row no-gutters>
                            <span class="body-1 tag-wrap">
                                <v-alert class="tag mx-3 my-1 pa-0 text-center red" 
                                    :class="`${roadtrip.labelColor}`"
                                    text--white
                                    width="100"
                                    dense
                                    prominent
                                    text>{{roadtrip.label}}</v-alert>
                            </span>
                            <v-col cols="12" class="d-flex">
                                <span class="display-1">{{roadtrip.tripName}}</span>
                            </v-col>
                            <v-col cols="12" class="">{{formatDayMonth(roadtrip.date)}}</v-col>
                        </v-row>

                        <v-container class="px-0">
                            <v-row no-gutters class="blue-info">
                                <v-col :cols="colSize">
                                    <div class="py-3 pb-4 mr-2 primary darken-2 text-no-wrap rounded-lg">
                                        <div class="pl-3"><v-img :src="carIcon" contain></v-img></div>
                                        <div class="icon-text text-end pr-3">{{roadtrip.checkedInCarCount}} / {{roadtrip.totalCarCount}}</div>
                                    </div>
                                </v-col>
                                <v-col :cols="colSize">
                                    <div class="py-3 pb-4 mx-2 primary darken-2 text-no-wrap rounded-lg">
                                        <div class="pl-3"><v-img :src="participantIcon" contain></v-img></div>
                                        <div class="icon-text text-end pr-3">{{roadtrip.checkedInPeopleCount}} / {{roadtrip.totalPeopleCount}}</div>
                                    </div>
                                </v-col>
                                <v-col :cols="colSize" v-if="roadtrip.offersLunch">
                                    <div class="py-3 pb-4 mx-2 primary darken-2 text-no-wrap rounded-lg">
                                        <div class="pl-3"><v-img :src="lunchIcon" contain></v-img></div>
                                        <div class="icon-text text-end pr-3">{{roadtrip.checkedInLunchCount}} / {{roadtrip.totalLunchCount}}</div>
                                    </div>
                                </v-col>
                                <v-col :cols="colSize" v-if="roadtrip.offersDinner">
                                    <div class="py-3 pb-4 ml-2 primary darken-2 text-no-wrap rounded-lg">
                                        <div class="pl-3"><v-img :src="dinnerIcon" contain></v-img></div>
                                        <div class="icon-text text-end pr-3">{{roadtrip.checkedInDinnerCount}} / {{roadtrip.totalDinnerCount}}</div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>

                        <v-row no-gutters>
                            <v-col cols="12" class="header mt-4 mb-2 d-flex">
                                <span class="display-1">Voedselvoorkeuren</span>
                            </v-col>
                            <!-- only show filter when there are really allergies from any participants -->
                            <v-col cols="12" v-show="this.roadtrip.generalFoodRestrictionInfo.length > 0">
                                <div class="allergy-filter">
                                    <v-select 
                                    v-model="allergyFilter"
                                    :items="allergyFilters"
                                    offset-y
                                    dense></v-select>
                                </div>
                            </v-col>
                            <v-col cols="12" v-show="filteredAllergies.length > 0">
                                <div class="pt-2">
                                    <v-item-group multiple>
                                        <v-item
                                        v-for="allergy in filteredAllergies"
                                        :key="allergy.item1">
                                        <v-chip class="mb-2 mr-2">
                                            {{ `${getFoodRestriction(allergy.item1)}:` }}
                                            <span class="pl-2">{{allergy.item2}}</span>
                                        </v-chip>
                                        </v-item>
                                    </v-item-group>
                                </div>
                            </v-col>
                            <v-col cols="12" v-show="filteredAllergies.length == 0">
                                <div>-</div>
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" class="header mt-4 mb-2">
                                <span class="display-1">Nog niet aanwezig</span>
                            </v-col>
                            <v-col cols="12" class="pt-3 pb-1 px-0" v-for="(person, index) in roadtrip.notCheckedIn" :key="index" v-show="hasNotCheckedInPersons">
                                <v-card class="blue darken-4 rounded-lg mb-2" @click="goToCheckinPage(person.orderId)">
                                    <v-card-title
                                    class="ols-12 pt-2 pb-0">
                                        {{ person.orderNumber }}
                                    </v-card-title>
                                    <v-card-title
                                    class="subtitle-1 cols-12 py-0">
                                        {{ getCardText(person) }}
                                    </v-card-title>
                                    <v-card-subtitle class="pt-4">{{ getCardSubText(person) }} </v-card-subtitle>
                                </v-card>
                            </v-col>
                            <v-col cols="12" v-show="!hasNotCheckedInPersons">
                                <div>-</div>
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" class="header mt-4 mb-2">
                                <span class="display-1">Aanwezig</span>
                            </v-col>
                            <v-col cols="12" class="pt-3 pb-1 px-0" v-for="(person, index) in roadtrip.checkedIn" :key="`checkin-${index}`" v-show="hasCheckedInPersons">
                                <v-card class="primary rounded-lg mb-2" @click="goToCheckinPage(person.orderId)">
                                    <v-card-title
                                    class="cols-12 pt-2 pb-0">
                                        {{ person.orderNumber }}
                                    </v-card-title>
                                    <v-card-title
                                    class="subtitle-1 cols-12 py-0">
                                        {{ getCardText(person) }}
                                    </v-card-title>
                                    <v-card-subtitle class="pt-4">{{ getCardSubText(person) }} </v-card-subtitle>
                                </v-card>
                            </v-col>
                            <v-col cols="12" v-show="!hasCheckedInPersons">
                                <div>-</div>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </BannerTemplate>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import LoadingIcon from '@/components/common/LoadingIcon';
import DataMixin from '@/mixins/data.mixin';
import RouteMixin from '@/mixins/route.mixin';
import FormatMixin from '@/mixins/format.mixin';
import RoadtripMixin from '@/mixins/roadtrip.mixin';
import MenuHelperMixin from '@/mixins/menu-helper.mixin';
import BannerTemplate from '@/components/common/BannerTemplate';

export default {
    name: 'TripDayStatistics',
    components: {
        LoadingIcon,
        BannerTemplate
    },
    computed: {
        ...mapState('authorizedModule', ['isAdmin']),
        hasNotCheckedInPersons()
        {
            return this.roadtrip.notCheckedIn.length > 0;
        },
        hasCheckedInPersons()
        {
            return this.roadtrip.checkedIn.length > 0;
        },
        colSize()
        {
            return this.roadtrip.offersLunch && this.roadtrip.offersDinner ? 3 : 4;
        },
        filteredAllergies()
        {
            switch (this.allergyFilter) {
                case 'Alles':
                    return this.roadtrip.generalFoodRestrictionInfo;
                case 'Lunch':
                    return this.roadtrip.lunchPackageFoodRestrictionInfo;
                case 'Diner':
                    return this.roadtrip.dinnerFoodRestrictionInfo;
                default:
                    return this.roadtrip.generalFoodRestrictionInfo;
            }
        }
    },
    mixins: [DataMixin, RouteMixin, FormatMixin, RoadtripMixin, MenuHelperMixin],
    watch: {
        isAdmin: {
            immediate: true,
            handler() {
                this.roadtripId = this.$route.params.roadtripId;
                if (this.isAdmin){
                    this.getTripDayStatistics();
                }
                else
                {
                    this.isLoading = false;
                }
            }
        }
    },
    data: () => ({
        isLoading: true,
        carIcon: require('@/assets/icons/car-v2.svg'),
        participantIcon: require('@/assets/icons/participant.svg'),
        lunchIcon: require('@/assets/icons/lunch.svg'),
        dinnerIcon: require('@/assets/icons/dinner.svg'),
        roadtripId: '',
        roadtrip: {
            generalFoodRestrictionInfo: [],
            notCheckedIn: [],
            checkedIn: []
        },
        allergyFilter: 'Alles',
        allergyFilters: [
            'Alles'
        ]

    }),  
    beforeCreate(){
        this.$store.dispatch('menuHelper/getRoadtripLabels');
        this.$store.dispatch('menuHelper/getFoodRestrictions');
    },
    mounted () 
    {
        this.$store.commit('setFooterVisibility', true);
    },
    methods: {
        getTripDayStatistics()
        {
            this.isLoading = true;
            this.$store.dispatch('roadtripModule/getTripDayStatistics', this.roadtripId)
                .then(data => {
                    this.roadtrip = this.mapRoadtripData(data);
                    this.isLoading = false;

                    if (data.offersLunch) {
                        this.allergyFilters.push('Lunch');
                    }

                    if (data.offersDinner) {
                        this.allergyFilters.push('Diner');
                    }
            })
            .catch(() => { this.isLoading = false; });
        },
        getCardText(person)
        {
            return `${person.firstName} ${person.lastName} | ${person.vehicleName}`;
        },
        getCardSubText(person)
        {
            let lunchInfo = this.roadtrip.offersLunch ? ` | ${person.lunchCount} lunch` : '';
            let dinnerInfo = this.roadtrip.offersDinner ? ` | ${person.dinnerCount} diner` : '';
            return `${this.getCurrency(person.orderPrice)} | ${person.passengerCount} passagiers${lunchInfo}${dinnerInfo}`;
        }
    }
}
</script>
<style scoped>
.tripday-statistics-content .tag-wrap 
{
    margin-top: -55px;
    position: absolute;
    right: 10px;
}


.blue-info .v-image 
{
    width: 30px;
    height: 30px;
}

.blue-info .v-image .v-image__image
{
    background-position: center right;
}

.blue-info {
    font-size: 24px;
}

.blue-info .icon-text
{
    padding-left: 10px;
    line-height: 30px;
}

.allergy-filter {
    width: 100px;
}

</style>
